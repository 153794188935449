import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import api from '../../service/api';
import { useParams } from "react-router";
import styles from "./index.module.css";
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarVida() {
    const { id } = useParams();

    // const navigate = useNavigate();

    const [vida, setVida] = useState([]);

    useEffect(() => {
        api.get('vida/' + id).then(({ data }) => {
            setVida(data);
        });
    }, [id]);

    // async function deleteVida(id) {
    //     api
    //         .delete("vida/" + id, {
    //             id: id
    //         })
    //         .then(() => {
    //             alert("Vida inativado!");
    //             navigate("/vidas", { replace: true });
    //         });
    // }


    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Lista de Vidas &gt; Perfil Vida
            </div>
            <div className={styles.title}>
                Perfil Vida
            </div>
            <div className={styles.divButton}>
                <Link to={`/editarVida/${vida.id}`}>
                    <Button size="sm" className={styles.buttonEditar}><MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar</Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
                </div>
                <div className={styles.linha} />
                <div className={styles.divDadosVida}>
                    <Row xs="3" >
                        <Col >
                            Nome <p className={styles.p}>{vida.nome}</p>
                        </Col>

                        <Col >
                            Nome Social <p className={styles.p}>{vida.nome_social}</p>
                        </Col>
                        <Col >
                            Data de Nascimento <p className={styles.p}>{vida.data_nascimento}</p>
                        </Col>
                        <Col >
                            Gênero <p className={styles.p}>{vida.genero}</p>
                        </Col>
                        <Col >
                            CPF <p className={styles.p}>{vida.cpf}</p>
                        </Col>
                        <Col >
                            Estado Civil <p className={styles.p}>{vida.estado_civil}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
                </div>
                <div className={styles.linha} />
                <div className={styles.divContatoVida}>

                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{vida.telefone}</p>
                        </Col>
                        <Col xs="6" >
                            E-mail <p className={styles.p}>{vida.email}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}

export default VisualizarVida;
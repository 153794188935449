import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import api from "../service/api";

const Context = createContext();

function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorLogin, setErrorLogin] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
            setAuthenticated(true);
        }
        setLoading(false);
    }, []);


    async function handleClickLogin(values) {
        api
            .post("login", {
                email: values.email,
                senha: values.senha,
            })
            .then((response) => {
                const token = response.data.token;
                const email = response.data.email;
                localStorage.setItem("token", JSON.stringify(token));
                localStorage.setItem("email", email);
                api.defaults.headers.Authorization = `Bearer ${token}`;
                setAuthenticated(true);
                navigate("/home", { replace: true });
            }).catch((error) => {
                if (error.response) {
                    setErrorLogin(error.response.status);
                } //Logs a string: Error: Request failed with status code 404
            });
    }

    function handleClickLogout() {
        setAuthenticated(false);
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        api.defaults.headers.Authorization = undefined;
        navigate("/login");
    }

    return (
        <Context.Provider
            value={{ authenticated, loading, errorLogin, handleClickLogin, handleClickLogout }}
        >
            {children}
        </Context.Provider>
    );
}

export { Context, AuthProvider };
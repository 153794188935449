import React, { useContext } from "react";
import { Routes as ReactRoutes, Route, Navigate, Outlet } from "react-router";
import { Context } from "./context/authContext";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Parceiro from "./pages/Parceiro";
import CadastroParceiro from "./pages/CadastroParceiros";
import VisualizarParceiro from './pages/VisualizarParceiro';
import EditarParceiro from './pages/EditarParceiro';
import Vidas from "./pages/Vidas"
import CadastroVida from './pages/CadastroVidas';
import VisualizarVida from './pages/VisualizarVida';
import EditarVida from './pages/EditarVida';
import Auxiliares from './pages/Auxiliares';
import CadastroAuxiliar from './pages/CadastroAuxiliar';
import VisualizarAuxiliar from './pages/VisualizarAuxiliar';
import EditarAuxiliar from './pages/EditarAuxiliar';
import LoadingComponent from './components/Loading/LoadingComponent';
import Questionarios from './pages/Questionarios';
import VisualizarQuestionario from './pages/VisualizarQuestionario';
import Perguntas from './pages/Perguntas';
import VidaDetalhes from './pages/VidaDetalhes';

function PrivateRoute() {
    const { authenticated, loading } = useContext(Context);

    if (loading) {
        return <LoadingComponent />;
    }

    return authenticated ? <Outlet /> : <Navigate to="/login" />;
}

function Routes() {
    const { authenticated } = useContext(Context);
    return (
        <ReactRoutes>
            <Route path="/" element={authenticated ? <Home /> : <Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/perguntas/:id" element={<Perguntas />} />
            <Route element={<PrivateRoute />}>
                <Route index path="/home" element={<Home />} />
                <Route path="/vidas" element={<Vidas />} />
                <Route path="/vida/:id" element={<VidaDetalhes />} />
                <Route path="/cadastroVida" element={<CadastroVida />} />
                <Route path="/visualizarVida/:id" element={<VisualizarVida />} />
                <Route path="/editarVida/:id" element={<EditarVida />} />
                <Route path="/parceiros" element={<Parceiro />} />
                <Route path="/cadastroParceiro" element={<CadastroParceiro />} />
                <Route path="/visualizarParceiro/:id" element={<VisualizarParceiro />} />
                <Route path="/editarParceiro/:id" element={<EditarParceiro />} />
                <Route path="/auxiliares" element={<Auxiliares />} />
                <Route path="/cadastroAuxiliar" element={<CadastroAuxiliar />} />
                <Route path="/visualizarAuxiliar/:id" element={<VisualizarAuxiliar />} />
                <Route path="/editarAuxiliar/:id" element={<EditarAuxiliar />} />
                <Route path="/questionarios" element={<Questionarios />} />
                <Route path="/visualizarQuestionario/:id" element={<VisualizarQuestionario />} />
            </Route>
        </ReactRoutes>
    );
}

export default Routes;

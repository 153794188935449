import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import DashBoardComponent from '../../components/DashBoard/DashBoardComponent';
import styles from './index.module.css';
import api from '../../service/api';

function PatientDetails() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Pega o nome passado via estado de navegação
  const nomePaciente = location.state?.nome || 'Nome não disponível';

  const [vida, setVida] = useState({ nome: nomePaciente, tendencias: [] });
  const [isLoading, setIsLoading] = useState(true);

  // Função para processar os dados da API
  const processarDadosvida = useCallback((dadosApi) => {
    const questionarioMap = {
      2: 'Ansiedade',
      3: 'Depressão',
      4: 'Bipolaridade',
      5: 'Dependência',
      6: 'T. Alimentação',
      9: 'Burnout',
    };

    const categorias = ['Ansiedade', 'Depressão', 'Bipolaridade', 'Dependência', 'T. Alimentação', 'Burnout'];
    const tendencias = categorias.map(() => 0);

    dadosApi.resultados.forEach((resultado) => {
      resultado.scores.forEach((scoreObj) => {
        const questionarioId = parseInt(scoreObj.questionario_id, 10);
        const categoria = questionarioMap[questionarioId];
        const valorNormalizado = (parseFloat(scoreObj.score) / 100) * 5;

        const indexCategoria = categorias.indexOf(categoria);
        if (indexCategoria !== -1) {
          tendencias[indexCategoria] = valorNormalizado;
        }
      });
    });

    return { nome: nomePaciente, tendencias };
  }, [nomePaciente]);

  useEffect(() => {
    const fetchVida = async () => {
      try {
        const { data } = await api.get(`/score/${id}`);
        const vidaProcessado = processarDadosvida(data);
        setVida(vidaProcessado);
      } catch (error) {
        console.error('Erro ao buscar dados do paciente:', error);
        setVida(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVida();
  }, [id, processarDadosvida]);

  if (isLoading) {
    return (
      <div className={styles.mainDiv}>
        <NavbarComponent />
        <NavMenuComponent />
        <div className={styles.content}>
          <p>Carregando dados do paciente...</p>
        </div>
      </div>
    );
  }

  if (!vida) {
    return (
      <div className={styles.mainDiv}>
        <NavbarComponent />
        <NavMenuComponent />
        <div className={styles.content}>
          <p>Paciente não encontrado.</p>
          <button onClick={() => navigate(-1)} className={styles.backButton}>Voltar</button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.mainDiv}>
      <NavbarComponent />
      <NavMenuComponent />
      <div className={styles.content}>
        <button onClick={() => navigate(-1)} className={styles.backButton}>Voltar</button>
        <h1>Tendências de {vida.nome}</h1>
        <DashBoardComponent vidas={[vida]} />
      </div>
    </div>
  );
}

export default PatientDetails;

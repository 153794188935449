import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import LoadingComponent from '../../components/Loading/LoadingComponent';
import styles from './index.module.css';
import { Row, Col, Table, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye } from 'react-icons/ai';
import api from '../../service/api'; // Importar sua instância do axios

function Home() {
    const [respostas, setRespostas] = useState([]);
    const [respostasFiltradas, setRespostasFiltradas] = useState([]);
    const [busca, setBusca] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Função para buscar os dados do endpoint real usando axios
    const fetchRespostas = async () => {
        try {
            const response = await api.get('/vida/ultimas-respostas'); // Chamada ao endpoint
            setRespostas(response.data);  // Ajuste conforme a estrutura da resposta
            setIsLoading(false);
        } catch (error) {
            setError(error.response ? error.response.data.message : error.message);
            setIsLoading(false);
        }
    };

    // Carregar os dados ao montar o componente
    useEffect(() => {
        fetchRespostas();
    }, []);

    // Atualizar as respostas filtradas quando a busca mudar
    useEffect(() => {
        const lowerBusca = busca.toLowerCase();
        const filtrados = respostas.filter(resposta => 
            resposta.nome && resposta.nome.toLowerCase().includes(lowerBusca) // Verificando se nome existe
        );
        setRespostasFiltradas(filtrados);
    }, [busca, respostas]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (error) {
        return <div>Erro: {error}</div>;
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Dashboard
            </div>
            <div className={styles.title}>
                Lista de Respostas
            </div>
            <div className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="9">
                                <InputGroup>
                                    <Input size="sm" placeholder="Nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </Row>
                    </InputGroup>
                </div>

                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Parceiro</th>
                            <th>Questionário</th>
                            <th>Última Resposta</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {busca 
                            ? respostasFiltradas.map((resposta) => (
                                <tr key={resposta.id}>
                                    <td className={styles.tbody}>{resposta.nome}</td>
                                    <td className={styles.tbody}>{resposta.parceiro_nome}</td>
                                    <td className={styles.tbody}>{resposta.questionario_nome}</td>
                                    <td className={styles.tbody}>{resposta.ultima_resposta}</td>
                                    <td>
                                        <Link to={`/vida/${resposta.id}`} state={{ nome: resposta.nome }}>
                                            <Button size="sm" className={styles.buttonPerfil}>
                                                <AiOutlineEye size={17} className={styles.buttonIcon} />
                                                Ver Tendências
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                            : respostas.map((resposta) => (
                                <tr key={resposta.id}>
                                    <td className={styles.tbody}>{resposta.nome}</td>
                                    <td className={styles.tbody}>{resposta.parceiro_nome}</td>
                                    <td className={styles.tbody}>{resposta.questionario_nome}</td>
                                    <td className={styles.tbody}>{resposta.ultima_resposta}</td>
                                    <td>
                                        <Link to={`/vida/${resposta.id}`} state={{ nome: resposta.nome }}>
                                            <Button size="sm" className={styles.buttonPerfil}>
                                                <AiOutlineEye size={17} className={styles.buttonIcon} />
                                                Ver Tendências
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Home;

import React from 'react';
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router";
import { useEffect, useState } from 'react';
import api from '../../service/api';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import ModalQuestionariosComponent from '../../components/ModalQuestionarios/ModalQuestionariosComponent';
import styles from './index.module.css';
import { Row, Col, Table, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye, AiOutlineArrowRight } from 'react-icons/ai';

function Vidas() {

    // const navigate = useNavigate();

    const [vidas, setVidas] = useState([]);
    const [vidasFiltrados, setVidasFiltratos] = useState([]);
    const [statusVida, setStatusVida] = useState("ativo");
    const [busca, setBusca] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [vida, setVida] = useState("");
    const [vidaTelefone, setVidaTelefone] = useState("");

    useEffect(() => {
        api.get('vida').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.nome < obj2.nome ? -1 :
                    (obj1.nome > obj2.nome ? 1 : 0);
            });
            setVidas(dataSort);
        });
    }, []);

    // async function deleteVida(id) {
    //     api
    //         .delete("vida/" + id, {
    //             id: id
    //         })
    //         .then(() => {
    //             alert("Vida inativado!");
    //             navigate("/vidas", { replace: true });
    //         });
    // }

    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = vidas.filter((vida) => vida.nome.toLocaleLowerCase().includes(lowerBusca));
        setVidasFiltratos(filtrados);
    }, [busca, vidas]);


    const enviarQuestionario = (p, t) => {
        setVida(p);
        setVidaTelefone(t);
        setIsOpen(true);
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            {isOpen ? <ModalQuestionariosComponent vida={`${vida}`} telefone={`${vidaTelefone}`} /> : null}

            <div className={styles.route}>
                Home &gt; Lista de Vidas
            </div>
            <div className={styles.title}>
                Lista de Vidas
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="6">
                                <InputGroup>
                                    <Input size="sm" placeholder="Nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col xs="6">
                                <div className={styles.selectFiltro}>
                                    <InputGroup>
                                        <Input value={statusVida} onChange={evento => setStatusVida(evento.target.value)} name="select" type="select" size="sm" className={styles.selectFiltro}>
                                            <option value="ativo">
                                                Ativo
                                            </option>
                                            <option value="inativo">
                                                Inativo
                                            </option>
                                        </Input>
                                        <InputGroupText className={styles.textFiltro}>
                                            Filtro
                            </InputGroupText>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </InputGroup>

                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                Vida
                            </th>
                            <th>
                                Data de Nascimento
                            </th>
                            <th>
                                CPF
                            </th>
                            <th>
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {busca ?
                            vidasFiltrados.filter(p => p.status === statusVida).map((vida) => (
                                <tr >
                                    <td className={styles.tbody}>
                                        {vida.nome}
                                    </td>
                                    <td className={styles.tbody}>
                                        {vida.data_nascimento}
                                    </td>
                                    <td className={styles.tbody}>
                                        {vida.cpf}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarVida/${vida.id}`}><Button size="sm" className={styles.buttonPerfil}><AiOutlineEye size={17} className={styles.buttonIcon} />Ver perfil</Button></Link>
                                    </td>
                                    <td>
                                        <Button size="sm" onClick={() => enviarQuestionario(vida.id, vida.telefone)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar Formulário</Button>
                                    </td>
                                </tr>
                            ))
                            :
                            vidas.filter(p => p.status === statusVida).map((vida) => (
                                <tr >
                                    <td className={styles.tbody}>
                                        {vida.nome}
                                    </td>
                                    <td className={styles.tbody}>
                                        {vida.data_nascimento}
                                    </td>
                                    <td className={styles.tbody}>
                                        {vida.cpf}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarVida/${vida.id}`}><Button size="sm" className={styles.buttonPerfil}><AiOutlineEye size={17} className={styles.buttonIcon} />Ver perfil</Button></Link>
                                    </td>
                                    <td>
                                        <Button size="sm" onClick={() => enviarQuestionario(vida.id, vida.telefone)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar Questionário</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>

        </div >
    );
}

export default Vidas;
import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import api from '../../service/api';
import { useParams } from "react-router";
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarParceiro() {

    const { id } = useParams();

    // const navigate = useNavigate();

    const [parceiro, setParceiro] = useState([]);

    useEffect(() => {
        api.get('parceiro/' + id).then(({ data }) => {
            setParceiro(data);
        });
    }, [id]);

    // async function deleteParceiro(id) {
    //     api
    //         .delete("parceiro/" + id, {
    //             id: id
    //         })
    //         .then(() => {
    //             alert("Parceiro inativado!");
    //             navigate("/parceiros", { replace: true });
    //         });
    // }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Lista de Parceiros  &gt; Perfil Parceiro 
        </div>
            <div className={styles.title}>
                Perfil Parceiro 
        </div>
            <div className={styles.divButton}>
                <Link to={`/editarParceiro/${parceiro.id}`}>
                    <Button size="sm" className={styles.buttonEditar}><MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar</Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
            </div>
                <div className={styles.linha} />
                <div className={styles.divDadosParceiro}>
                    <Row xs="3" >
                        <Col >
                            Nome <p className={styles.p}>{parceiro.nome}</p>
                        </Col>

                        <Col >
                            Nome Social <p className={styles.p}>{parceiro.nome_social}</p>
                        </Col>
                        <Col >
                            Data de Nascimento <p className={styles.p}>{parceiro.data_nascimento}</p>
                        </Col>
                        <Col >
                            Gênero <p className={styles.p}>{parceiro.genero}</p>
                        </Col>
                        <Col >
                            CPF <p className={styles.p}>{parceiro.cpf}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
            </div>
                <div className={styles.linha} />
                <div className={styles.divContatoParceiro}>

                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{parceiro.telefone}</p>
                        </Col>
                        <Col xs="6" >
                            E-mail <p className={styles.p}>{parceiro.email}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divDadosParceiros}>
                    Dados Parceiros
            </div>
                <div className={styles.linha} />
                <div className={styles.divInfoParceiro}>

                    <Row xs="3">
                        <Col >
                            Especialidade <p className={styles.p}>{parceiro.especialidade}</p>
                        </Col>
                        <Col >
                            Conselho Regional <p className={styles.p}>{parceiro.conselho_regional}</p>
                        </Col>
                        <Col >
                            UF Consolhe Regional <p className={styles.p}>{parceiro.uf_conselho_regional}</p>
                        </Col>
                        <Col >
                            Registro Parceiro <p className={styles.p}>{parceiro.registro_parceiro}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}

export default VisualizarParceiro;